// Fonts
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans');

// Variables
@import 'variables';

// Helpers
@import 'helpers';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'buttons';

// Font Awesome Free
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

// Overwritten Slick Styles
@import 'slick';

*,*:focus,*:hover{
    outline:none;
}


html {
    scroll-behavior: smooth;
}

body {
    color: #343a40;
}

// Overwrites

.btn {
    border-radius: 0;
    //font-weight: bold;
    text-transform: uppercase;
}

.cursor-pointer {
    cursor:pointer;
}

// Custom Styles

.container-slim {
    max-width: 630px;
}

h1, h2, h3, h4, h5, h6 {
    color: $primary;
}

footer {
    //border-top: 1px solid #dddddd;
    background-color: #303030;
}

.font-size-small {
    font-size: 15px;
}

.font-size-smaller {
    font-size: 13px;
}

.text-shadow {
    text-shadow: 0 0 8px rgba(0,0,0,0.5);
}

.footer-logo {
    max-width: 300px;
}

.footer-social {
    max-width: 32px;
    display: inline-block;
}

.footer-bar {
    //border-top: 1px solid #dddddd;
    background: #2C2C2C;
}

.title-social {
    max-width: 38px;
    display: inline-block;
}

.nav-link > img {
    height:24px;
}

.navbar-brand > img {
    height:48px;
}

.article-card {
    width: 100%;
    padding-top: 51%;
    position: relative;
    overflow: hidden;
}

.article-card-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    transition: 250ms ease-in-out;
}

.article-card:hover .article-card-image {
    opacity: 0.5;
}

.article-card-inner {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);
    text-shadow: 0 0 8px #000000;
}

.staff-card {
    width: 100%;
    padding-top: 148%;
    position: relative;
    overflow: hidden;
}

.staff-card-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    transition: 250ms ease-in-out;
}

.staff-card:hover .staff-card-image {
    opacity: 1;
}

.staff-card:hover .staff-card-inner {
    opacity: 1;
}

.see-more-button-transition {
    transform: translateY(100px);
    transition: 350ms ease-in-out;
}

.staff-card:hover .see-more-button-transition {
    transform: translateY(0);
    transition: 350ms ease-in-out;
}

.staff-card-inner {
    //backdrop-filter: blur(4px);
    transition:250ms;
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.25);
    border: 4px solid #00BEFF;
    background: linear-gradient(0deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,.25) 25%);
}

.thumb-card {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
}

.thumb-card-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    transition: 250ms ease-in-out;
}

.thumb-card:hover .thumb-card-image {
    opacity: 1;
}

.thumb-card:hover .thumb-card-inner {
    opacity: 1;
}

.see-more-button-transition {
    transform: translateY(100px);
    transition: 350ms ease-in-out;
}

.thumb-card:hover .see-more-button-transition {
    transform: translateY(0);
    transition: 350ms ease-in-out;
}

.thumb-card-inner {
    //backdrop-filter: blur(4px);
    transition:250ms;
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid #00BEFF;
    background: linear-gradient(0deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,.25) 25%);
}

.scaleable {
    /*position: relative;*/
    transition: 250ms ease-in-out;
}

.scaleable:hover {
    transition: 250ms ease-in-out;
    //transform: scale(1.10);
    //z-index: 9999;
}

.zoomable .staff-card-image {
    background-size: 100% 100%;
    transition: 250ms;
}

.zoomable:hover .staff-card-image {
    background-size: 102% 102%;
}

.article-main-image {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    background-color: #674e64;
    background-position: center;
    background-size: cover;
}

.article-main-image-inner {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.55) 0%, rgba(0,0,0,0) 100%);
}

.title-secondary-banner {
    width: 100%;
    padding-top: 27%;
    position: relative;
    overflow: hidden;
    background-color: #674e64;
    background-position: center;
    background-size: cover;
}

.title-secondary-banner-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.teaser-button {
    width: 139px;
    height: 38px;
    background-image: url("/images/buttons/teaser-button.png");
    transition: 150ms ease-in-out;
    display: inline-block;
    transform:translateY(15px);
}

.teaser-button:hover {
    background-image: url("/images/buttons/teaser-button-hover.png");
}

.btn-round {
    border-radius: 19px;
    letter-spacing: 0.2em;
}

.multi-col-text {
    column-count: 2;
    column-width: 250px;
}


.nav-link {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: 250ms;
}

.nav-link.active{
    //border-bottom: 3px solid #00BEFF;
    transition: 250ms;
}
//
//.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
//    border-bottom: 3px solid #00BEFF;
//    transition: 250ms;
//}

.full-title-banner {
    position: relative;
    //min-height:95vh;
    padding-top:41.66%;
    //background-color: red;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.full-title-banner-inner {
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0.8015581232492998) 50%, rgba(0,0,0,0) 100%);
}

.full-title-banner-bottom {
    position: absolute;
    right:0;
    left: 0;
    bottom:0;
}


.ar {
    width:100%;
    padding-top: 56.25%;
    position: relative;
}

.ar-inner {
    position: absolute;
    top:0;
    right:0;
    left: 0;
    bottom:0;
}

.ar-bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.ar-home-banner {
    //padding-top:49.68%; // Old Value
    padding-top:36.45%;
}

.ar-home-banner-inner {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgb(0,0,0);
    //background: linear-gradient(90deg, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0) 100%);
    background: radial-gradient(at bottom left, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 50%);
}

.ar-horizontal-banner {
    padding-top:40%;
}

.ar-horizontal-banner-inner {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgb(0,0,0);
    //background: linear-gradient(90deg, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0) 100%);
    //background: radial-gradient(at bottom left, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 50%);
    //background: linear-gradient(90deg, rgba(255,255,255,1) 33%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,1) 33%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
}

.ar-horizontal-banner-inner-flipped {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgb(0,0,0);
    //background: linear-gradient(90deg, rgba(0,0,0,0.65) 50%, rgba(0,0,0,0) 100%);
    //background: radial-gradient(at bottom left, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 50%);
    //background: linear-gradient(265deg, rgba(255,255,255,1) 33%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: linear-gradient(265deg, rgba(0,0,0,1) 33%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
}

.ar-home-news-banner {
    padding-top:56.25%;
}

.ar-home-news-banner-inner {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 35%);
}


// Watch At Home Style

.player-thumb-wrapper {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    background-position: left;
    background-size:cover;
}

.player-thumb-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}




.markdown-white > h1,
.markdown-white > h2,
.markdown-white > h3,
.markdown-white > h4,
.markdown-white > h5,
.markdown-white > h6 {
    color: #FFFFFF;
}


.card {
    border-radius: 0;
}

.social-hover {
    position:absolute;
    top:0;
    right:0;
    background: red;
    opacity: 0;
    transition: 250ms;
    padding: .5rem;
    width:100px;
    height:50px;
}

.social-hover-cue .social-item {
    opacity: 1;
    transition: 250ms;
}

.image-caption {
    background-color: #F7F7F7;
    padding: .5rem 1rem;
    font-style: italic;
    p {
        margin-bottom: 0;
    }
}


a.no-link {
    text-decoration: none!important;
    &:hover {
        text-decoration: none!important;
        opacity: .75;
    }
}

//.scaleable {
//    background-position: center;
//    background-size: cover;
//    transition: 0.15s ease-in-out;
//    position: relative;
//    height: 0;
//    overflow: hidden;
//}
//
//.scaleable:hover {
//    transform: scale(1.07);
//}
//
//.scaleable-poster {
//    padding-bottom: 150%;
//}
//
//.scaleable-square {
//    padding-bottom: 100%;
//}
//
//.scaleable-thumb {
//    padding-bottom: 56.25%;
//}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 95%;
    }
}




// HIGHLAND

.nav-link {
    font-size:1.2rem;
}

#topnavbar {
    transition: 250ms;
}

.navbar-scrolled, .navbar-opaque {
    //background-color: #00BEFF;
    //border-bottom: 1px solid #D4D4D4;
    // Old Brand Colours
    //background: rgb(79,134,185);
    //background: linear-gradient(135deg, rgba(79,134,185,1) 0%, rgba(106,169,222,1) 100%) !important;
    // New Brand Colours
    background: #00BEFF;
}

.navbar-responsive-styles {
    //background-color: #00BEFF;
    //border-bottom: 1px solid #D4D4D4;
    // Old Brand Colours
    //background: rgb(130,188,244);
    //background: linear-gradient(135deg, rgba(130,188,244,1) 0%, rgba(130,188,244,1) 100%);
    // New Brand Colours
    background: #00BEFF;
    //position: fixed;
}

.mt-74-desktop {
    margin-top:74px;
}

@media (min-width: 992px) {
    .nav-link {
        font-size:0.9rem;
    }
    .navbar-responsive-styles {
        //background-color: transparent;
        border-bottom: none;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        //background: linear-gradient(180deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
        background: #00BEFF;
        z-index: 1030;
    }
}

@media (max-width: 992px) {
    .mt-74-desktop {
        margin-top:0;
    }
}

.horizontal-banner-text-wrapper {
    left:2rem;
    bottom:2rem;
    right:60%;
}

.horizontal-banner-flipped-text-wrapper {
    right:2rem;
    bottom:2rem;
    left:60%;
}

@media (max-width: 575px) {
    .horizontal-banner-text-wrapper {
        left:1rem;
        bottom:.5rem;
        right: .5rem;
    }

    .horizontal-banner-flipped-text-wrapper {
        right:1rem;
        bottom:.5rem;
        left: .5rem;
    }
}

.bg-subtle {
    background-color: #F1F1F1;
}

.article-row {
    border: 2px solid transparent;
    transition:250ms;
    &:hover {
        border: 2px solid #00BEFF;
        transition:250ms;
    }
}

.home-description-expander {
    // Put these back if want to show on hover
    //max-height:0;
    //transition: 500ms;
    //opacity: 0;
    //overflow: hidden;
    max-height:500px;
    transition: 500ms;
    opacity: 1;
    overflow: auto;
}

.ar-home-banner:hover .home-description-expander,
.ar-horizontal-banner:hover .home-description-expander,
.ar-home-news-banner:hover .home-description-expander
{
    max-height:500px;
    transition: 500ms;
    opacity: 1;
    overflow: auto;
}

.vol-toggle-wrapper {
    background-color: rgba(255,255,255,.75);
    border-radius: 0.5rem;
    min-width: 3.25rem;
}

.vol-toggle {
    font-size:1.75rem;
    padding: 0.5rem;
}


.slick-prev {
    /* Move prev button into slider bounds */
    left: 0;
}

.slick-next {
    /* Move next button into slider bounds */
    right: 0;
}

.slick-prev, .slick-next {
    height: 80%;
    width: 4rem;
    -webkit-text-stroke: 1px rgba(0,0,0,0.65);
    /* Make sure prev/next buttons are on top of slider */
    z-index: 1;

}

.slick-prev:before {
    /* Uses Font Awesome Unicode icon */
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f053";
}

.slick-next:before {
    /* Uses Font Awesome Unicode icon */
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    content: "\f054";
}

.slick-prev:before, .slick-next:before {
    font-size: 2rem;
}

.home-slide-markdown-wrapper {
    margin-bottom: 1rem;
}

.home-slide-markdown-wrapper p {
    margin-bottom: 0;
}

a .home-slide-markdown-wrapper p {
    color:white;
}
