.btn-outline-facebook {
    color: #3b5998;
    border-color: #3b5998;
}

.btn-outline-facebook:hover {
    color: #ffffff;
    background-color: #3b5998;
    border-color: #3b5998;
}

.btn-outline-facebook:focus,
.btn-outline-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 169, 222, 0.5);
}

.btn-outline-twitter {
    color: #1DA1F2;
    border-color: #1DA1F2;
}

.btn-outline-twitter:hover {
    color: #ffffff;
    background-color: #1DA1F2;
    border-color: #1DA1F2;
}

.btn-outline-twitter:focus,
.btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 169, 222, 0.5);
}

.btn-outline-instagram {
    color: #4c68d7;
    border-color: #4c68d7;
}

.btn-outline-instagram:hover {
    color: #ffffff;
    background-color: #4c68d7;
    border-color: #4c68d7;
}

.btn-outline-instagram:focus,
.btn-outline-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 169, 222, 0.5);
}

.btn-outline-youtube {
    color: #FF0000;
    border-color: #FF0000;
}

.btn-outline-youtube:hover {
    color: #ffffff;
    background-color: #FF0000;
    border-color: #FF0000;
}

.btn-outline-youtube:focus,
.btn-outline-youtube.focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 169, 222, 0.5);
}

.btn-outline-linkedin {
    color: #0e76a8;
    border-color: #0e76a8;
}

.btn-outline-linkedin:hover {
    color: #ffffff;
    background-color: #0e76a8;
    border-color: #0e76a8;
}

.btn-outline-linkedin:focus,
.btn-outline-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(106, 169, 222, 0.5);
}
